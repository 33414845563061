<script>
import { defineComponent } from 'vue'
import { Pie } from 'vue3-chart-v2'

export default defineComponent({
  name: 'RemindersBreakdown',
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    }
  },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  computed: {
    labels() {
      return {
        'first.reminder': this.t('First reminder'),
        'second.reminder': this.t('Second reminder'),
        'final.demand': this.t('Final demand'),
        'summons': this.t('Summons'),
        'collection.case': this.t('Collections / Bailiff'),
      }
    }
  },
  extends: Pie,
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: Object.values(this.labels),
      datasets: [
        {
          label: this.t('Overdue amounts per reminder'),
          backgroundColor: [
            '#48C78E',
            '#79acf8',
            '#f8eb79',
            '#f87979',
            '#f079f8',
            '#79f8cc',
            '#d98f5a'],
          data: Object.keys(this.labels).map((item) => {
            let i = this.chartData.filter((cid) => {
              return cid.last_reminder_identifier === item;
            });
            return i[0] ? i[0].total_amount ?? undefined : undefined;
          }),
        }
      ]
    })
  },
  methods: {
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number);
    },
  }
})
</script>