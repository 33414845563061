<template>
  <div id="Dash">
    <div class="columns">
      <div class="column is-6">
        <div class="box is-shadowless">
          <OverdueBreakdown v-if="!loading.OverdueBreakdown" :chartData="chartData.OverdueBreakdown" :height="200"/>
        </div>
      </div>
      <div class="column is-6">
        <div class="box is-shadowless">
          <RemindersBreakdown v-if="!loading.RemindersBreakdown" :chartData="chartData.RemindersBreakdown" :height="200"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverdueBreakdown from './Charts/OverdueBreakdown'
import RemindersBreakdown from './Charts/RemindersBreakdown'
import {mapActions} from "vuex";
export default {
  components: {
    OverdueBreakdown,
    RemindersBreakdown,
  },
  data() {
    return {
      chartData: {
        OverdueBreakdown: [],
        RemindersBreakdown: [],
      },
      loading: {
        OverdueBreakdown: false,
        RemindersBreakdown: false,
      },
      mapping: {
        OverdueBreakdown: 'cm_incaze_nl_metrics_chart_001',
        RemindersBreakdown: 'cm_incaze_nl_metrics_chart_002',
      },
      handlers: {
        OverdueBreakdown: this.gotOverdueBreakdownResponse,
        RemindersBreakdown: this.gotRemindersBreakdownResponse,
      },
    };
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.chartData = {
        OverdueBreakdown: [],
        RemindersBreakdown: [],
      };
      this.getChartData('OverdueBreakdown');
      this.getChartData('RemindersBreakdown');
    },
  },
  created() {
    this.getChartData('OverdueBreakdown');
    this.getChartData('RemindersBreakdown');
  },
  methods: {
    ...mapActions([
      "dataviewKit/getViewData",
    ]),
    gotOverdueBreakdownResponse(data) {
      this.chartData.OverdueBreakdown = JSON.parse(data.data[0].chartdata);
    },
    gotRemindersBreakdownResponse(data) {
      this.chartData.RemindersBreakdown = data.data;
    },
    getChartData(chart = 'OverdueBreakdown') {
      this.loading[chart] = true;
      this["dataviewKit/getViewData"](
          {
            view: this.mapping[chart],
            parameters: {
              owner_identifier: (
                  this.$store.state.me.activeSeller ??
                  this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier,
            },
          },
          { "content-type": "application/json" }
      )
          .then((data) => {
            this.handlers[chart](data);
            this.loading[chart] = false;
          })
          .catch((data) => {
            console.error(data);
            this.$toast.error(data, {});
            this.loading[chart] = false;
          });
    }
  },
};
</script>
