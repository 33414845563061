<script>
import { defineComponent } from 'vue'
import { Bar } from 'vue3-chart-v2'

export default defineComponent({
  name: 'OverdueBreakdown',
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    }
  },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  extends: Bar,
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: [this.t('Open amount'), this.t('0 - 30 days'), this.t('31 - 60 days'), this.t('61 - 90 days'), this.t('90+ days')],
      datasets: [
        {
          label: this.t('Overdue amounts breakdown'),
          backgroundColor: ['#48C78E', '#f87979', '#f87979', '#f87979', '#f87979', '#f87979'],
          data: this.chartData,

        }
      ]});
  },
  methods: {
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number);
    },
  }
})
</script>